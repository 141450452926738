// Gujarati
export default{
    form: {
        startSurveyButton: "શરૂ કરીએ",
        nextButton: "આગલું",
        submitAnswerButton: "ઉત્તર મોકલો",
        continueButton: "ચાલુ રાખો",
        finishButton: "સમાપ્ત",
        headerPercentageCompletion: 'પૂર્ણ',
        headerQuestionsCompleted: 'પ્રશ્નો',
        headerDefaultErrorMessage: 'અરે! કશુંક ખોટું થયું.',
        brandingPoweredBy: 'Powered by',
        brandingPromoWhySurvey: 'તમે <b>{product}</b> નો ઉપયોગ કરી શકો છો, તો સર્વે કેમ?',
        brandingPromoGetStarted: 'આજે જ શરૂ કરો!',
        submissionPending: "તમારું voiceform મોકલી રહ્યું છે. કૃપા કરીને બ્રાઉઝર વિન્ડો બંધ ન કરો.",
        submissionCanCloseWindow: "તમારું voiceform સફળતાપૂર્વક મોકલાયું છે. હવે તમે વિન્ડો બંધ કરી શકો છો 👍",
        mediaAttachmentOverlayCloseButton: "બંધ કરો",
        expiredFormHeader: "આવવા માટે આભાર!",
        expiredFormMessage: "લાગે છે કે આ સર્વે સમાપ્ત થઈ ગયો છે. કૃપા કરીને voiceform સ્રષ્ટા સાથે સંપર્ક કરો.",
        notPublishedFormHeader: "Voiceform પ્રકાશિત નથી!",
        notPublishedFormMessage: "લાગે છે કે આ સર્વે હજુ સુધી પ્રકાશિત નથી. કૃપા કરીને voiceform સ્રષ્ટા સાથે સંપર્ક કરો.",
        notPublishedFormRetryButton: "ફરી પ્રયાસ કરો",
        errorFormHeader: "અરે!",
        errorFormMessage: "શાયદ કશુંક ખોટું થયું છે. કૃપા કરીને પછીથી ફરી પ્રયાસ કરો.",
        errorFormRetryButton: "ફરી પ્રયાસ કરો",
        emptyFormHeader: "આવવા માટે આભાર!",
        emptyFormMessage: "અરે! આ ફોર્મ હાલમાં ખાલી છે, અને તેમાં ક્યા પ્રશ્નો અથવા ફીલ્ડ્સ નથી. આ મુશ્કેલી માટે અમને ખેદ છે. આને સુધારવા માટે કૃપા કરીને ફોર્મ સ્રષ્ટા સાથે સંપર્ક કરો. તમારી સમજ અને સહકાર માટે આભાર!",

        restoreFormHeader: "લાગે છે કે તમારો ફોર્મ પ્રગતિ પર છે!",
        restoreFormMessage: "શું તમે ત્યાંથી જ ચાલુ રાખવા માંગો છો જ્યાંથી તમે છોડી દીધું હતું?",
        restoreFormButton: "ફોર્મ ચાલુ રાખો",
        restoreFormButtonNew: "ફરીથી શરૂ કરો",

        audioPermissionHeader: "શું તમે અવાજ સાથે જવાબ આપશો?",
        audioPermissionMessage: "બોલવાથી જવાબ ઝડપી અને સરળ થશે.",
        audioPermissionAcceptButton: "હા, હું ઈચ્છું છું",
        audioPermissionDenyButton: "ના, આભાર",
        audioPermissionErrorHeader: "માઇક્રોફોન ઉપલબ્ધ નથી",
        audioPermissionErrorMessage: "માઇક્રોફોનની ઍક્સેસને સક્રિય કરવા માટે કૃપા કરીને તમારા બ્રાઉઝરની અનુમતિઓ તપાસો.",
        audioPermissionErrorButton: "ઠીક છે",
        audioRecorderFailedUpload: "રેકોર્ડિંગ અપલોડ કરવાનું નિષ્ફળ ગયું",
        silenceDetectorError: "આપની અવાજ સાંભળી શકાતી નથી. કૃપા કરીને તમારા માઇક્રોફોનની પસંદગીઓ તપાસો.",
        audioRecorderButtonRecord: "રેકોર્ડ કરો",
        audioRecorderButtonRecordMore: "વધુ રેકોર્ડ કરો",
        voiceResponseSelectionMessage: "તમે કેવી રીતે જવાબ આપવાનું પસંદ કરશો...",
        voiceResponseSelectionOrDivider: "અથવા",
        voiceResponseTextInputPlaceholder: "ઉત્તર ટાઇપ કરો",
        voiceResponseTextInputCharacterCounterMinMessage: "કૃપા કરીને ઓછામાં ઓછા {min} અક્ષરો દાખલ કરો",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} અક્ષરો (ઓછામાં ઓછા {min} અક્ષરો)",
        voiceResponseTextInputCharacterValidationMessage: "{count} અક્ષરો (ઓછામાં ઓછા {min} અક્ષરો)",

        // checkbox
        checkboxValidationTooFew: "તમારે ઓછામાં ઓછા {min} વિકલ્પ(ઓ) પસંદ કરવી જ પડશે",
        checkboxValidationTooMany: "તમારે {max} થી વધુ વિકલ્પ(ઓ) પસંદ નહીં કરવી જોઈએ",
        checkboxNoneOfTheAboveOption: "ઉપરોક્ત પૈકી કોઈ નહીં",
        checkboxInvalidAnswer: "અમાન્ય જવાબ.",

        // datepicker
        datePickerPlaceholder: "તારીખ પસંદ કરો",

        // dropdown
        // email
        emailLabel: "ઈમેઇલ",
        emailInvalid: "અમાન્ય ઈમેઇલ.",
        // file-upload
        fileUploadPluginNameCamera: "કેમેરા",
        fileUploadPluginNameCameraVideo: "વિડિયો રેકોર્ડ કરો",
        fileUploadPluginScreenCast: "સ્ક્રીન કાસ્ટ",
        fileUploadVideoImportFilesDefault: 'વિડિયો રેકોર્ડ અથવા અપલોડ કરો:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'વિડિયો રેકોર્ડિંગ વિકલ્પ પસંદ કરો:',
        fileUploadVideoImportFilesNoCamera: 'સ્ક્રીન રેકોર્ડ કરવા માટે બટન દબાવો',
        fileUploadVideoImportFilesNoScreenCast: 'વિડિયો રેકોર્ડ કરવા માટે બટન દબાવો',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'વિડિયો રેકોર્ડ કરવા માટે કોઈ વિકલ્પ ઉપલબ્ધ નથી',

        // matrix
        matrixValidationMessage: "જવાબ માન્ય છે.",
        matrixRow: "પંક્તિ",
        matrixColumn: "કોલમ",
        matrixRowRequired: "પંક્તિ {rowTitle} આવશ્યક છે.",
        matrixRadioGroup: "રેડિયો ગ્રુપ",
        matrixCheckboxGroup: "ચેકબોક્સ ગ્રુપ",
        matrixGroupRequired: "પંક્તિ {rowTitle} માટે {type} {groupTitle} આવશ્યક છે.",
        matrixColumnRequired: "પંક્તિ {rowTitle} માટે કોલમ {columnTitle} આવશ્યક છે.",
        matrixColumnInvalid: "પંક્તિ {rowTitle} માટે કોલમ {columnTitle} અમાન્ય છે.",
        matrixRequired: "જરૂરી છે.",
        matrixNumericMustBeNumber: "સંખ્યા હોવી જોઈએ.",
        matrixNumericMustBeGreaterThenMin: "{min} કરતા વધુ હોવું જોઈએ.",
        matrixNumericMustBeLessThenMax: "{max} કરતા ઓછું હોવું જોઈએ.",
        matrixNumericMustBeInteger: "પૂર્ણાંક હોવું જોઈએ.",
        matrixNumericInvalidNumber: "અમાન્ય સંખ્યા.",

        // name
        nameLabel: "નામ",
        nameInvalid: "પ્રથમ અને છેલ્લું નામ દાખલ કરો.",

        // nps
        npsNotLikely: "કદાચ નથી",
        npsExtremelyLikely: "ખૂબ જ શક્ય",

        // numeric input
        numericInputRequired: "જરૂરી છે.",
        numericInputMustBeNumber: "સંખ્યા હોવી જોઈએ.",
        numericInputMustBeGreaterThenMin: "{min} કરતા વધુ હોવું જોઈએ.",
        numericInputMustBeLessThenMax: "{max} કરતા ઓછું હોવું જોઈએ.",
        numericInputMustBeInteger: "પૂર્ણાંક હોવું જોઈએ.",
        numericInputInvalidNumber: "અમાન્ય સંખ્યા.",
        numericInputPlaceholder: "સંખ્યા દાખલ કરો",

        // phone
        phoneInvalid: "અમાન્ય ફોન નંબર.",
        phoneCountrySelectorLabel: 'દેશ કોડ',
        phoneCountrySelectorError: 'દેશ પસંદ કરો',
        phoneNumberLabel: 'ફોન નંબર',
        phoneExample: 'ઉદાહરણ:',

        // boolean
        booleanYesLabel: "હા",
        booleanNoLabel: "ના",

        //questionStep
        questionStepAudioQuestionLabel: "ઑડિઓ પ્રશ્ન",

        // errors
        invalidPhoneNumber: "{phone} અમાન્ય ફોન નંબર છે.",
        invalidEmail: "{email} અમાન્ય ઇમેઇલ સરનામું છે.",
        questionIsRequired: "આ પ્રશ્ન જરૂરી છે.",
        answerIsNotValid: "જવાબ અમાન્ય છે.",
        unfinishedProbingDialogError: "કૃપા કરીને ડાયલોગ પૂર્ણ કરો.",
        cannotResumePartialResponse: "આધૂરી પ્રતિક્રિયા ફરી શરૂ કરી શકાતી નથી.",
        failedToSubmitForm: "ફોર્મ મોકલવામાં નિષ્ફળતા. ઇન્ટરનેટ કનેક્શન ચકાસો અને ફરી પ્રયાસ કરો.",

         //language picker
         searchLanguage: "ભાષા શોધો",
    }
}
